import React from 'react';
import wa from '../assets/wa.png';

const Wa = () => {
  return (
    <div className="whatsapp_main">
        <a href="https://wa.me/919850902785" target="_blank" rel="noreferrer">
          <img src={wa} alt="" />
        </a>
      </div>
  )
}

export default Wa