import React from 'react';

const Call = () => {
  return (
    <div className="call_btn_now">
    <a href="tel:+919850902785">
        Call Now
    </a> 
  </div>
  )
}

export default Call;