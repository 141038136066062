import React from 'react';

const New = (props) => {
  return (
    <div className='new'>
    <h2>{props.newH2}</h2>
    </div>
  )
}

export default New